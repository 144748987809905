import React, { lazy, Suspense } from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

import EngageBanner from "../components/box-engage-banner";
import BannerTickets from "../components/banner-tickets";
import BoxIniziativa from "../components/box-iniziativa";
import BoxHelp from "../components/box-help";

const Spline = lazy(() => import("@splinetool/react-spline"));

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="box-intro box-intro--page-name">
      <Suspense>
        <Spline
          scene="https://prod.spline.design/Xf2icR8i12xleigw/scene.splinecode"
          className="animation-circle"
        />
      </Suspense>
      <div className="box-intro__text">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="box-intro__page-name">
                <span>Pagina</span> non trovata
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <EngageBanner />

    <BannerTickets />

    <BoxIniziativa />

    <BoxHelp />
  </Layout>
);

export default NotFoundPage;
